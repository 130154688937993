import type { FormInstance } from 'antd';
import { InputNumber, message, Modal } from 'antd';
import * as echarts from 'echarts';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { api } from '../../../utils/api';
import type { Bill, NewTab, NoticeData, Response, ShopExpire, ShortCutEdit, ShortCutList, SystemData, TenantRechargeExpire, WayBillItem } from './interface';
import type { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react';

const blueAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#1978FF',
      },
      {
        offset: 1,
        color: '#1978FF00',
      },
    ]),
  },
};
const orignAreaStyle = {
  normal: {
    // 渐变填充色（线条下半部分）
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: '#FF670D',
      },
      {
        offset: 1,
        color: '#FF670D00',
      },
    ]),
  },
};

class DashboardStore {
  @observable public todoData = {
    waitCheck: 0, // 待审核
    waitPrint: 0, // 待打印
    waitReceive: 0, // 待验货
    unPrintBefore2Day: 0, // 超过2天未发货
    undelivered: 0, // 上次发货失败
    purchasedUnPrint: 0, // 采购完成未打印
  };

  @observable public shortCutData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public tenantRechargeExpire = {};

  @observable public shortCutEditData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public isShowEdit = false;

  @observable public noticeList: NoticeData[] = [];

  @observable public systemUpdateData: SystemData = {
    title: '',
    content: [],
  };

  @observable public feedBacktext = '';

  @observable public feedBackphone = '';

  @observable public feedBackformRef = React.createRef<FormInstance>();

  @observable public isShowShopExpire = false;

  @observable public isPrewarn = false; // 预警

  @observable public shopExpireList: ShopExpire[] = [];

  @observable public defaultActiveKey = 'deliveryOptions';

  @observable public topSaleData = [];

  @observable public topSaleInstance;

  @observable public deliveryInstance;

  @observable public baseOptions = {
    color: [
      '#1978FF',
      '#FF670D',
      '#1978FF',
    ],
    legend: {
      show: true,
      type: 'plain',
      right: 27,
      top: 0,
      icon: 'roundRect',
      itemHeight: 3,
      itemWidth: 14,
      data: [],
    },
    tooltip: {
      trigger: 'axis',

      // formatter: '{b}<br />{a} {c}件',
      axisPointer: {
        type: 'line',

        // lineStyle: { width: 10 },
        label: { width: 10 },
      },
    },
    xAxis:
      {
        // splitLine: { show: false },
        data: [],
        boundaryGap: false, // 防止统计图左侧和纵轴有间隙
        // splitLine: { show: true,lineStyle: {color: '#333',opacity: 0.05}},
        // axisLine: { show: true ,lineStyle: {color: '#333',opacity: 0.1 }},
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },

    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.05,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.1,
          },
        },
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.05,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#333',
            opacity: 0.1,
          },
        },
        axisTick: {
          show: true,
          lineStyle: { color: '#c5c3cb' },
        },
      },
    ],

    grid: { // 图表的位置
      left: 23,
      top: 34,
      right: 22,
      bottom: 10,
      containLabel: true, // false是依据坐标轴来对齐的,true是依据坐标轴上面的文字边界来对齐
    },

    series:
      {
        name: '',
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: [],
        areaStyle: blueAreaStyle,
      },
  };

  @observable public deliveryOptions = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '发货单数',
        '发货件数',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '发货单数',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '发货件数',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public orderOptions = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '销售单',
        '销售金额',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '销售单',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '销售金额',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public todayDelivery = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '发货单数',
        '发货件数',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '发货单数',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: '发货件数',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],

  };

  @observable public payedNotDelivery = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: [
        '订单数量',
        'sku数量',
      ],
    },
    series: [
      {
        showSymbol: false,
        smooth: true,
        areaStyle: blueAreaStyle,
        type: 'line',
        name: '订单数量',
        yAxisIndex: 0,
        data: [],
      },
      {
        showSymbol: false,
        smooth: true,
        type: 'line',
        name: 'sku数量',
        yAxisIndex: 1,
        data: [],
        areaStyle: orignAreaStyle,

      },
    ],
  };

  @observable public topSale = {
    ...this.baseOptions,
    legend: {
      ...this.baseOptions.legend,
      data: '订单数量',
    },
    series: {
      showSymbol: false,
      smooth: true,
      areaStyle: blueAreaStyle,
      type: 'line',
      name: '订单数量',
      yAxisIndex: 0,
      data: [
        1,
        21,
        3,
      ],
    },

  };

  // 图表
  @observable public tabsData = [
    {
      id: 'deliveryOptions',
      name: '发货量',
      option: this.deliveryOptions,
      url: api.delivergoods,
      showData: true,
    },
    {
      id: 'orderOptions',
      name: '订单量',
      option: this.orderOptions,
      url: api.salegoods,
      showData: true,
    },
    {
      id: 'todayDelivery',
      name: '当天发货',
      option: this.todayDelivery,
      url: api.todaygoods,
      showData: true,
    },
    {
      id: 'payedNotDelivery',
      name: '已付款未发货',
      option: this.payedNotDelivery,
      url: `${api.payedAndNotDelivery}?type=softWare`,
      showData: true,
    },
  ];

  @observable public billNotice: Bill[] = [];

  // 获取待办事项量
  public queryTodoData = action(() => {
    request(({ url: api.orderTodo })).then((res: Response) => {
      const { data } = res;
      this.todoData = {
        ...this.todoData,
        ...data,
      };
    });
    request(({ url: api.deliveryTodo })).then((res: Response) => {
      const { info } = res;
      this.todoData = {
        ...this.todoData,
        waitCheck: info['1'],
        waitPrint: info['2'],
        waitReceive: info['7'],
      };
    });
  });

  // 获取dashbord页面的一些数据
  public queryCommonInfo = action(() => {
    request({ url: `${api.queryCommonInfo}?homePageType=1` }).then((res: Response) => {
      const { systemNotice, shortcut, updateNotice } = res.data;
      this.shortCutData.current = shortcut;
      this.systemUpdateData = updateNotice || {};
      this.noticeList = systemNotice || [];
    });
  });

  // 获取电子面单
  public queryBillNotice = action(() => {
    request({ url: api.getWaybillNotice }).then((res: Response) => {
      this.billNotice = res.data;
    });
  });

  // 图表
  public queryOrderDelivery = action(() => {
    this.tabsData.map((item) => {
      request({ url: item.url }).then((res: Response) => {
        const { status } = res;
        if (status === 'NoModuleAuth') {
          item.showData = false;
        }

        const { xAxisData, numData, data } = res.data;

        this[item.id].xAxis = {
          ...this[item.id].xAxis,
          data: xAxisData,
        };
        this[item.id].series = [
          {
            ...this[item.id].series[0],
            data: data.data,
          },
          {
            ...this[item.id].series[1],
            data: numData.data,
          },
        ];
        this.deliveryInstance.updateEChartsOption();
      })
        .catch((res: Response) => {
          message.destroy();
          const { status } = res.data;
          if (status === 'NoModuleAuth') {
            item.showData = false;
          }
        });
    });
  });

  public getDeliveryRefData = (e) => {
    this.deliveryInstance = e;
  };

  public getTopSaleInstance = (e) => {
    this.topSaleInstance = e;
  };

  public queryTopSale = action(() => {
    request({ url: api.getSevenDaysTopProducts }).then((res: Response) => {
      const result = res.data.map((item) => {
        return {
          ...item,
          option: {
            ...this.topSale,
            xAxis: {
              ...this.topSale,
              boundaryGap: false,
              data: item.xAxisData,
            },
            series: {
              ...this.topSale.series,
              data: item.numData,
            },
          },
        };
      });
      this.topSaleData = result;
    });
  });

  // 快捷入口切换弹窗显隐
  public toggleShortcutEdit = action((isShow: boolean) => {
    this.isShowEdit = isShow;
    if (isShow) {
      this.shortCutEditData = this.shortCutData;
      this.queryMenuList();
    }
  });

  // 快捷入口的菜单列表
  public queryMenuList = action(() => {
    request({
      url: api.getAllMenus,
      method: 'POST',
      data: { homePageType: 1 },
    }).then((res: ShortCutList[]) => {
      if (!res) {
        return;
      }
      const resultData = res.map((item) => {
        const filterMenu = item.menus.filter((child) => {
          const index = _.findIndex(this.shortCutData.current, [
            'id',
            child.id,
          ]);
          index > -1 && this.handleShortCutCurrentData(index, item.id);
          return index === -1;
        });
        return {
          ...item,
          menus: filterMenu,
        };
      });
      this.shortCutData.surplus = resultData;
    });
  });

  // 给已经选择的快捷方式添加父级ID
  public handleShortCutCurrentData(index, id) {
    const data: ShortCutList[] = this.shortCutData.current;
    data[index] = {
      ...data[index],
      parentId: id,
    };
    this.shortCutData.current = data;
  }

  // 快捷入口添加删除操作
  public handleShortCutEdit = action((item, type, parentId, index): void => {
    const { surplus, current } = this.shortCutEditData;
    const isAdd = type === 'ADD';
    if (isAdd) {
      if (current.length === 10) {
        message.destroy();
        message.info('最多添加10个');
        return;
      }

      // 从菜单目录里去除要添加的快捷项
      const chooseMenu = surplus[index];
      const menus = chooseMenu.menus.filter((menu) => menu.id !== item.id);
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };
      this.shortCutEditData = {
        surplus: result,
        current: [
          ...current,
          {
            ...item,
            parentId,
          },
        ],
      };
    } else {
      const index = _.findIndex(surplus, [
        'id',
        item.parentId,
      ]);
      const chooseMenu = surplus[index];
      const menus = [
        ...chooseMenu.menus,
        item,
      ];
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };

      this.shortCutEditData = {
        surplus: result,
        current: current.filter((i) => i.id !== item.id),
      };
    }
  });

  // 快捷入口保存
  public handleShortCutSave = action(() => {
    const { current } = this.shortCutEditData;
    const params = current.map((item) => item.id);
    request({
      method: 'post',
      url: api.saveShortcut,
      data: { ids: params.join(',') },
    }).then(() => {
      this.shortCutData = this.shortCutEditData;
      this.toggleShortcutEdit(false);
    })
      .catch((res: Response) => {
        message.error(res.data.data);
      });
  });

  // 快捷入口跳转
  public handleShortCutOpenTab = (item: NewTab): void => {
    // 销售开单等菜单要携带仓库ID
    const warehouseMenu = sessionStorage.getItem('warehouseMenu');
    const warehouseList = warehouseMenu ? JSON.parse(warehouseMenu) : [];
    const currentWareHouse = sessionStorage.getItem('currentWareHouse');
    const url = warehouseList.includes(Number(item.id)) ? `${item.url}?shopAndWarehouseId=${currentWareHouse}` : item.url;
    window.top.egenie.openTab(url, item.id, item.name, item.icon);
  };

  // 联系客服
  public handleCopy = (phone) => {
    const ele: Partial<HTMLInputElement> = document.getElementById('copyText');
    ele.value = phone;

    ele.select();
    document.execCommand('copy');
  };

  public handleSubmit = (values): void => {
    console.log('values...', values);
    const { feedBackphone, feedBacktext } = values;
    if (!feedBacktext || !feedBackphone) {
      message.error('信息填写不全');
      return null;
    }
    request({
      method: 'post',
      url: api.commitFeedback,
      data: {
        content: feedBacktext,
        mobile: feedBackphone,
      },
    }).then((res: Response) => {
      message.success('反馈成功');
      this.feedBackformRef.current.setFieldsValue({
        feedBackphone: '',
        feedBacktext: '',
      });
    })
      .catch(() => {
        message.destroy();
        message.error('手机号格式错误');
      });
  };

  // 店铺过期
  public getShopExpireList = action(() => {
    request({ url: api.shopExpireList }).then((res: Response) => {
      if (!res.data) {
        return;
      }
      this.shopExpireList = res.data;
      this.toggleShopExpire(true);
    });
  });

  public handleShopAgain = action((shopId) => {
    request({ url: `${api.shopAgain}/${shopId}` }).then((res: Response) => {
      window.open(res.data);
      this.toggleShopExpire(false);
    });
  });

  public toggleShopExpire = action((isShow: boolean) => {
    this.isShowShopExpire = isShow;
  });

  // 处理启用和禁用
  public handleAbleShop = (id): void => {
    Modal.confirm({
      centered: true,
      title: '提醒',
      content: '确定要禁用吗？',
      onOk: () => {
        request({
          url: '/api/baseinfo/rest/shop/batchDisableShop',
          method: 'post',
          data: [id],
        }).then((res: Response) => {
          this.getShopExpireList();
          message.success('禁用成功');
        });
      },
    });
  };

  public onClickServiceSubscriptionRebatesButton = () => {
    window?.top?.egenie?.openTab('/egenie-ts-baseinfo/serviceSubscriptionRebates/index', 1800, '服务订购返款');
  };

  @observable public wayBillRows: WayBillItem[] = [];

  @observable.ref public wayBillColumns: ColumnsType<WayBillItem> = [
    {
      title: '序号',
      dataIndex: '_index',
      width: 60,
    },
    {
      title: '快递公司',
      dataIndex: 'courierName',
      width: 150,
    },
    {
      title: '面单渠道',
      dataIndex: 'channel',
      width: 120,
    },
    {
      title: '店铺名称',
      dataIndex: 'shopName',
      width: 150,
    },
    {
      title: '网点信息',
      width: 250,
      dataIndex: 'branchName',
      render: (_, row) => (row.branchName ? `${row.branchName}(${row.branchCode || ''})` : ''),
    },
    {
      title: '剩余单量',
      width: 80,
      dataIndex: 'balance',
    },
    {
      title: '预警值',
      dataIndex: 'prewarnValue',
      width: 120,
      render: (_, row) => (
        <Observer>
          {
            () => (
              <InputNumber
                max={99999999}
                min={0}
                onChange={(value) => row.prewarnValue = value}
                placeholder="请输入预警值"
                size="small"
                value={row.prewarnValue}
              />
            )
          }
        </Observer>
      ),
    },
  ].map((item) => ({
    ...item,
    ellipsis: true,
  }));

  /**
   * 预警成功按钮
   */
  @action public onPrewarnOk = async() => {
    try {
      this.isPrewarn = true;
      const req = await request<BaseData>({
        method: 'POST',
        url: '/api/wms/rest/waybill/updatePrewarnValue',
        data: { prewarnList: this.wayBillRows },
      });
      message.success('预警设置成功');
      this.onPrewarnCancel();
    } catch (e) {
      console.error(e);
    }
  };

  @action public onPrewarnCancel = () => {
    this.isPrewarn = false;
    this.wayBillRows = [];
  };

  @action public onWithdrawal = async() => {
    try {
      this.isPrewarn = true;
      const req = await request<BaseData<any[]>>({
        method: 'POST',
        url: '/api/wms/rest/waybill/queryWayBillBalance',
        data: { channel: '' },
      });
      this.wayBillRows = req.data.map((item, index) => {
        return {
          ...item,
          _id: nanoid(),
          _index: index + 1,
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action public getRechargeStatistics = async(): Promise<void> => {
    try {
      const res: BaseData<TenantRechargeExpire> = await request({ url: '/api/tenant/tenantRecharge/statistics' });
      this.tenantRechargeExpire = res.data;
    } catch (err) {
      console.error(err);
    }
  };
}
export default new DashboardStore();
